/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, equal, filterBy, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getSeatInfo } from 'embercom/lib/admins/seat-info';

export default Controller.extend({
  queryParams: ['filterType', 'filterValue'],
  filterType: null,
  filterValue: null,

  appController: controller('apps/app'),
  appsController: controller('apps'),
  me: alias('appsController.model'),
  app: reads('appController.model'),
  store: service(),
  customerService: service(),
  intercomConfirmService: service(),
  invitedAdmins: null,
  selectedTeamStatus: 'editing',
  isSaving: false,
  isEditing: equal('selectedTeamStatus', 'editing'),
  selectedTab: 'teammates',

  inviteeEmail: '',
  activeInvitedAdmins: filterBy('invitedAdmins', 'active', true),

  sendFailString: 'There was a problem sending the invite, please contact customer support.',
  sendDenialString: `We've prevented your app from sending email, including invites, as your app failed our app review process.
    Please contact customer support for more information or visit https://www.intercom.com/help/pricing-privacy-and-terms/intercoms-app-review-process`,

  teamSorting: ['membershipSortKey', 'name'],
  showBulkInviteModal: false,

  hideEmptyStateBanner: computed(
    'activeInvitedAdmins.length',
    'app.{humanAdmins.length}',
    function () {
      return this.app.humanAdmins.length > 1 || this.activeInvitedAdmins.length > 0;
    },
  ),

  shouldShowUpgradeBanner: computed(
    'customerService.customer.freeSeatCount,seatLimit',
    'app.{canUseSeatOverages,isSalesforceContracted}',
    function () {
      let customer = this.customerService.customer;
      return (
        !this.app.isSalesforceContracted ||
        (this.app.isSalesforceContracted &&
          !this.app.canUseSeatOverages &&
          customer.freeSeatCount >= customer.seatLimit)
      );
    },
  ),

  showMoreSeatsArticle: () => {
    window.Intercom('showArticle', 8205716); // https://www.intercom.com/help/en/articles/8205716-intercom-seats
  },
  showTeammateArticle: () => {
    window.Intercom('showArticle', 280); // https://www.intercom.com/help/en/articles/280-add-remove-delete-or-export-a-teammate
  },
  showPermissionsArticle: () => {
    window.Intercom('showArticle', 176); // https://www.intercom.com/help/en/articles/176-permissions-how-to-restrict-access-for-some-teammates
  },

  createAdminInvite() {
    let defaultValueForSettingsAndBillingPermissions = false;
    return this.store.createRecord('invited-admin', {
      app_id: this.get('app.id'),
      can_export_data: true,
      can_send_messages: true,
      can_redact_conversation_parts: true,
      can_reassign_conversations: true,
      can_access_billing_settings: defaultValueForSettingsAndBillingPermissions,
      can_access_product_settings: defaultValueForSettingsAndBillingPermissions,
      can_access_workspace_settings: defaultValueForSettingsAndBillingPermissions,
      can_create_and_edit_bots: true,
    });
  },

  async _refreshAdminInvites() {
    let invitedAdmins = await this.store.findAll('invited-admin', { reload: true });
    this.set('invitedAdmins', invitedAdmins);
  },

  loadSeatInfo: task(function* () {
    let seatInfo = yield getSeatInfo({ numberOfSeatsRequired: 1 });
    this.set('seatInfo', seatInfo);
  }),

  actions: {
    updatePaywalls() {
      if (this.app.canSeeCostChangeCard) {
        this.loadSeatInfo.perform();
      }
    },
    reloadPermissions() {
      return this.app.fetchAndUpdateAllAdminPermissions();
    },
    showConfirmationBox() {
      return this.intercomConfirmService.confirm(this.confirmOptions);
    },
    onInviteSentOrSaved() {
      this._refreshAdminInvites();
    },
  },
});
